<template>
  <div class="recruiting-big">
    <!-- top -->
    <div class="recruiting-top dfr cross-center">
      <div class="fs28 fw400 fc999">已选</div>
      <div class="top-number dfr cross-center">
        <div class="fs36 fw600 fctheme">{{ siDongList.length }}</div>
        <div class="w-number fs28 fw400 fc999">
          /
          <span v-if="this.meetingInfo">{{
            this.meetingInfo.need_directory_user_count
          }}</span>
        </div>
      </div>
    </div>
    <!-- item -->
    <div v-if="siDongList.length" class="dfc flex-center">
      <div
        class="recruiting-item"
        v-for="(item, index) in siDongList"
        :key="index"
      >
        <div class="item-box">
          <div class="dfr main-between">
            <div class="dfr">
              <!-- 头像 -->
              <div class="item-portrait-box">
                <!-- 用户头像 -->
                <img
                  v-if="item.user.avatar"
                  :src="item.user.avatar"
                  alt=""
                  class="w100 h100 object-cover"
                />
                <!-- 默认头像 -->
                <img
                  v-else
                  src="../assets/images/default-portrait.png"
                  alt=""
                  class="w100 h100"
                />
                <!-- 徽章 -->
                <div v-if="item.user.is_major_auth" class="item-badge-box">
                  <img
                    src="../assets/images/home-emblem.png"
                    alt=""
                    class="w100 h100"
                  />
                </div>
              </div>
              <!-- 信息 -->
              <div class="item-message">
                <div class="dfc">
                  <div class="dfr cross-center">
                    <!-- 姓名 -->
                    <div class="fs32 fw600 fc333">{{ item.user.name }}</div>
                    <!-- 等级 -->
                    <div v-if="item.come_from == 1" class="name-plate-z">
                      <name-plate nameplate="主持人指定" />
                    </div>
                    <div v-else class="name-plate-z">
                      <name-plate
                        nameplate="案主指定"
                        background="linear-gradient(90deg, #7B7B7B 0%, #5A5A5A 100%)"
                      />
                    </div>
                  </div>
                  <div class="item-com-job dfr cross-center flex-wrap">
                    <!-- 职位 -->
                    <div class="fs24 fw400 fc999">{{ item.user.job }}</div>

                    <div
                      style="
                        width: 0.02rem;
                        height: 0.2rem;
                        background: #cccccc;
                        margin: 0 0.12rem;
                      "
                    />

                    <div class="fs24 fw400 fc999">{{ item.user.company }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 删除img 0案主指定 1主持人指定 -->
            <!-- type 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 -->
            <div
              v-if="type == 1 || type == 0"
              class="item-delete dfr cross-start"
              @click="deleteClick(item.user.id)"
            >
              <img
                src="../assets/images/invite-delete.png"
                alt=""
                class="w100 h100"
              />
            </div>
          </div>
        </div>
        <!-- 描述 -->
        <div class="field-item-box dfr flex-wrap">
          <div v-for="(item_, index_) in item.user.field" :key="index_">
            <div class="field-item fs20 fw400 fc666">{{ item_.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <empty v-else></empty>
    <!-- 按钮 -->
    <div class="recruiting-button-wrap">
      <div class="w100 dfr main-between cross-center recruiting-button-b">
        <div @click="showShare" class="button-w">
          <icy-button
            width="3.24rem"
            heigth="0.8rem"
            border-radius="0.44rem"
            border="1px solid #CB9025"
            background="#F7F7F7"
            color="#CB9025"
          >
            <div class="button-img">
              <img
                src="../assets/images/share-01.png"
                alt=""
                class="w100 h100"
              />
            </div>
            分享邀请</icy-button
          >
        </div>
        <div class="button-t" @click="goAddSiDong">
          <icy-button width="3.24rem" heigth="0.8rem" border-radius="0.44rem">
            <div class="button-img">
              <img src="../assets/images/tjia.png" alt="" class="w100 h100" />
            </div>
            手动添加</icy-button
          >
        </div>
      </div>
    </div>
    <!-- 返回首页 -->
    <icy-backhome></icy-backhome>
  </div>
</template>

<script>
export default {
  data() {
    return {
      siDongList: [],
      id: "",
      userId: "",
      shareLink: "",
      fieldId: "",
      type: "",
      meetingInfo: null,
    };
  },
  async created() {
    this.$title("招募参会私董");
    this.id = parseInt(this.$route.query.id) || "";
    this.fieldId = parseInt(this.$route.query.fieldId) || "";
    this.type = parseInt(this.$route.query.type);
    // type 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0

    this.getSiDongList();
    await this.getUserId();
    await this.initMeetingDetail();
    this.initPage();
  },
  methods: {
    async getUserId() {
      const resp = await this.$API.get("userInfo", false).then((resp) => resp);
      if (resp.code == 1000) {
        this.userId = resp.data.info.id;
      }
    },
    initShare() {
      const shareLink =
        location.origin +
        location.pathname +
        `#/invite?meetingId=${this.id}&userId=${this.userId}`;
      this.shareLink = shareLink;
      let field = "";
      if (this.meetingInfo.field) {
        field = this.meetingInfo.field.name;
      }
      let info = `我的困惑是如何「${this.meetingInfo.questions[0]}」这对「${this.meetingInfo.questions[1]}」很重要，我针对这个问题已做了「${this.meetingInfo.questions[2]}」，我希望帮我「 ${this.meetingInfo.questions[3]}」`;

      const shareInfo = {
        title: `${
          this.meetingInfo.user.name || this.meetingInfo.user.phone
        }发起的${field}私董会邀请你的参加`,
        desc: `${info}`,
        // 朋友圈标题描述
        longTitle: `${info}`,
        link: shareLink,
        /**
           * "http://web.marketinguniversity.cn/share-icon1.png",
            "http://sidonghui.shifang.tech/share-icon1.png"
           */
        imgUrl: [location.origin + "/share-icon1.png"],
      };

      this.$bus.$emit("hasShare", shareInfo);
    },
    initPage() {
      this.initShare();
    },
    async initMeetingDetail() {
      const resp = await this.$API
        .get("meetingDetail", { id: this.id })
        .then((resp) => resp);
      if (resp.code == 1000) {
        this.meetingInfo = resp.data;
      }
    },
    async getSiDongList() {
      const resp = await this.$API
        .get("joinMeetingSidongList", { id: this.id })
        .then((resp) => resp);
      if (resp.code == 1000) {
        this.siDongList = resp.data.list;
      }
    },
    goAddSiDong() {
      this.$go("addSiDong", { id: this.id, fieldId: null });
    },
    // 删除点击事件
    deleteClick(userId) {
      this.deleteSiDong(userId);
    },
    // 删除接口
    async deleteSiDong(userId) {
      const resp = await this.$API
        .post("deleteSiDong", { meetingId: this.id, userId: userId })
        .then((resp) => resp);
      if (resp.code == 1000) {
        this.$toast.success("删除成功");
        this.getSiDongList();
      }
    },
    showShare() {
      if (this.showShare) {
        this.$bus.$emit("showShareGuide", true);
        this.$bus.$emit("shareGuideHref", this.shareLink);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.recruiting-big {
  background: #f7f7f7;
  min-height: 100vh;

  padding-top: 0.26rem;
  .recruiting-top {
    margin-left: 0.28rem;
    .top-number {
      margin-left: 0.16rem;
      .w-number {
        margin-left: 0.08rem;
      }
    }
  }
  .recruiting-item {
    width: 6.94rem;
    background: #ffffff;
    margin-top: 0.28rem;
    border-radius: 0.12rem;
    padding: 0.36rem;
    .item-box {
      .item-portrait-box {
        width: 0.96rem;
        height: 0.96rem;
        position: relative;
        img {
          border-radius: 50%;
          object-fit: cover;
        }
        .item-badge-box {
          width: 0.28rem;
          height: 0.28rem;
          position: absolute;
          right: 0;
          bottom: 0;
          img {
            position: absolute;
            object-fit: cover;
          }
        }
      }
      .item-message {
        margin-left: 0.2rem;
        .name-plate-z {
          margin-left: 0.16rem;
        }
        .item-com-job {
          margin-top: 0.12rem;
        }
      }
      .item-delete {
        width: 0.28rem;
        height: 0.28rem;
        // margin-right: 0.36rem;
        img {
          object-fit: cover;
        }
      }
    }
    .field-item-box {
      .field-item {
        background: #f6f6f6;
        padding: 0.08rem 0.16rem;
        margin: 0.12rem 0.12rem 0 0;
      }
    }
  }
  .recruiting-item:last-child {
    margin-bottom: 0.4rem;
  }
  .recruiting-button-wrap {
    height: calc(1.2rem + env(safe-area-inset-bottom));
    height: calc(1.2rem + constant(safe-area-inset-bottom));
    background: #f7f7f7 !important;
    .recruiting-button-b {
      position: fixed;
      bottom: 0;
      padding: 0.2rem 0.36rem;
      padding-bottom: calc(0.2rem + env(safe-area-inset-bottom));
      padding-bottom: calc(0.2rem + constant(safe-area-inset-bottom));
      .button-img {
        width: 0.32rem;
        height: 0.32rem;
        margin-right: 0.12rem;
        img {
          object-fit: cover;
        }
      }
    }
  }
}
</style>
